import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/index.scss';
import { graphql } from 'gatsby';
import ReactPageScroller from '../../lib/index';

import Layout from '../../layouts/en';
import SEO from '../../components/seo';

import MainSection from '../../pages-sections/сorporate-сitizenship/main-section/main-section';
import Commitment from '../../pages-sections/сorporate-сitizenship/commitment/commitment';
// import EventSection from '../../pages-sections/сorporate-сitizenship/event/event';
import Stategy from '../../pages-sections/сorporate-сitizenship/strategy/strategy';
import ContactCorporate from '../../pages-sections/сorporate-сitizenship/contact-corporate/contact-corporate';
import LineIndicator from '../../components/line-indicator/line-indicator';
import Pagination from '../../components/pagination/pagination';
import {
  getPageData,
  returnNum,
  setRtl,
  unsetRtl,
} from '../../helpers/helpers';
import useGoToPage from '../../hooks/useGoToPage';

const CorporateCitizenship = ({ location, data }) => {
  const locationLink = location.hash.length ? returnNum(location.hash) : 1;
  const [currentPage, setCurrentPage] = useState(locationLink);
  const [lineHeight, setLineHeight] = useState(0);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollUpOnHover] = useState(false);
  const [canScrollDownOnHover] = useState(false);
  const [windowSize, setWindowSize] = useState(0);
  const [showSections, setShowSections] = useState(false);

  const isMenuMobile = windowSize <= 1024;
  const isMobile = windowSize <= 1024 || window.innerHeight < 720;
  const wpData = data && getPageData(data, 'corporate-citizenship-ar');
  useEffect(() => {
    setRtl(location.pathname);
    return () => {
      unsetRtl();
    };
  }, []);

  const MENU_ITEMS = [
    wpData.section_2_name,
    // wpData.event_name,
    wpData.section_4_name,
  ]; // 'Quote' add when show section
  const [ref, goToPage] = useGoToPage(location, isMobile, MENU_ITEMS, currentPage);

  const onChangeSection = ({ index, name }) => {
    goToPage(index, name);
  };

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
      || document.body.clientWidth;
    setWindowSize(width);
  };

  const handleWindowScroll = () => {
    const scrollBlocker = window.pageYOffset > 0 || window.pageYOffset < 0;

    setCanScrollUp(scrollBlocker);
    return true;
  };

  useEffect(() => {
    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
    if (!isMobile) {
      document.addEventListener('scroll', handleWindowScroll, { passive: false });
      setShowSections(false);
    } else {
      setShowSections(true);
    }
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  useEffect(() => {
    const activePaginationButton = document.querySelector('button[data-activeitem="active"]');
    const topPosition = activePaginationButton
      ? activePaginationButton.getBoundingClientRect().top + 20 : 486;
    setLineHeight(topPosition);
    setTimeout(() => {
      if (showSections !== (currentPage === MENU_ITEMS.length + 1)) {
        setShowSections(currentPage === MENU_ITEMS.length + 1);
      }
    }, 1000); // time for render sections that are out of pageScroller
  }, [currentPage, windowSize]);

  if (windowSize === 0) {
    return (<></>);
  }

  const renderSections = () => (
    [
      <MainSection
        activeSection={currentPage}
        title={wpData.section_1_name}
        isMobile={isMenuMobile}
        data={
          {
            title: wpData.section_1_title,
            image: wpData.section_1_background_image,
          }
        }
      />,
      <Commitment
        activeSection={currentPage}
        isMobile={isMobile}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_2_name}
          />
        )}
        sectionName={wpData.section_2_name}
        data={
          {
            title: wpData.section_2_title,
            subtitle: wpData.section_2_subtitle,
            count_icon_1: wpData.count_icon_1,
            count_1: wpData.count_1,
            count_text_1: wpData.count_text_1,
            count_icon_2: wpData.count_icon_2,
            count_2: wpData.count_2,
            count_text_2: wpData.count_text_2,
            count_icon_3: wpData.count_icon_3,
          }
        }
      />,
      // <EventSection
      //   activeSection={currentPage}
      //   isMobile={isMenuMobile}
      //   index={1}
      //   Pagination={(
      //     <Pagination
      //       menuItems={MENU_ITEMS}
      //       changeHandler={onChangeSection}
      //       activeItem={wpData.event_name}
      //     />
      //   )}
      //   sectionName={wpData.event_name}
      //   data={
      //     {
      //       name: wpData.event_name,
      //       bg: wpData.event_bg,
      //       slider: wpData.event_slider,
      //     }
      //   }
      // />,
      <Stategy
        activeSection={currentPage}
        isMobile={isMenuMobile}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_4_name}
          />
          )}
        sectionName={wpData.section_4_name}
        data={
         {
           title: wpData.section_4_title,
           description: wpData.section_4_description,
           table: wpData.s_table,
           description_2: wpData.section_4_description_2,
           link: wpData.section_4_link,
         }
       }
      />,
    ]
  );

  return (
    <>
      <Layout
        currentSection={currentPage}
        sectionsCount={MENU_ITEMS.length + 1}
        location={location}
        isMobile={isMenuMobile}
        changeHandler={onChangeSection}
        showFooter={showSections}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
            copyright: wpData.copyright,
          }
        }
      >
        <SEO title={wpData.section_1_name} />
        <div data-currentPage={`currentPage_${currentPage}`}>
          {!isMobile ? (
            <ReactPageScroller
              ref={ref}
              pageOnChange={(num) => setCurrentPage(num)}
              customPageNumber={currentPage}
              transitionTimingFunction="cubic-bezier(.435,.01,.135,1)"
              blockScrollUp={canScrollUpOnHover || canScrollUp}
              blockScrollDown={canScrollDownOnHover || showSections}
              pagination={(
                <Pagination
                  canScrollUp={canScrollUp}
                  menuItems={MENU_ITEMS}
                  changeHandler={onChangeSection}
                  activeItem={currentPage}
                />
              )}
            >
              {renderSections().map((section) => section)}
            </ReactPageScroller>
          ) : (
            <div ref={ref}>
              {renderSections().map((section) => section)}
            </div>
          )}
          <LineIndicator
            height={lineHeight}
            activeItem={currentPage}
          />
          { (showSections || isMobile)
          && (
            <ContactCorporate
              activeSection={currentPage}
              data={
                {
                  title: wpData.section_5_title,
                  link: wpData.section_5_link,
                }
              }
            />
          )}
        </div>
      </Layout>
    </>
  );
};

CorporateCitizenship.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

CorporateCitizenship.defaultProps = {
  data: null,
};

export default CorporateCitizenship;

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          acf {
            section_1_name
            section_1_title
            section_1_background_image {
              alt
              url
            }
            
            section_2_name
            section_2_title
            section_2_subtitle
            count_1
            count_text_1
            count_icon_1 {
              alt
              url
            }
            count_2
            count_text_2
            count_icon_2 {
              alt
              url
            }
            count_icon_3 {
              alt
              url
            }
            
            event_name
            event_bg
            event_slider {
              event_s_item_1 {
                event_s_item_title
                event_s_item_description
                event_s_item_link
              }
              event_s_item_2 {
                event_s_item_title
                event_s_item_description
                event_s_item_link
              }
              event_s_item_3 {
                event_s_item_title
                event_s_item_description
                event_s_item_link
              }
            }
            
            section_4_name
            section_4_title
            section_4_description
            s_table {
              s_table_h_1
              s_table_h_2
              s_table_t_1
              s_table_t_2
              s_table_t_3
              s_table_t_4
              s_table_t_5
              s_table_t_6
            }
            section_4_description_2
            section_4_link
            
            section_5_title
            section_5_link
            
            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
            copyright
          }
          title
          slug
        }
      }
    }
  }`;
