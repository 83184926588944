// Core
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

// Instruments
import LineIndicator from '../../../components/line-indicator/line-indicator';
import s from './strategy.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import LinkBlock from '../../../components/link/link';
import { replaceSpace } from '../../../helpers/helpers';

const Strategy = ({
  activeSection,
  data,
  isMobile,
  sectionName,
  Pagination,
}) => {
  const [sectionLoaded, setSectionLoaded] = useState(false);
  const [lineHeight, setLineHeight] = useState(0);
  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${s.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 40px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  useEffect(() => {
    setSectionLoaded(true);
  }, []);

  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={`active_${sectionLoaded ? activeSection : 0}`} className={`${s.strategySection}`}>
      <VerticalLines startAnimation={sectionLoaded} />
      <Container>
        <div className={`${s.animatedTextContainer} ${s.animatedTextContainerBox}`}>
          <div className={s.animatedText}>
            <h3 className={`${s.pageTitle} ${s.h3}`}>{data.title}</h3>
          </div>
          <div className={s.animatedText}>
            <div className={s.pageDescription}>{data.description}</div>
          </div>
          <div className={`${s.animatedTextContainer} ${s.tableContainer}`}>
            <table border="0">
              <tr className={`${s.animatedText} ${s.row1}`}>
                <th><p className={s.p}>{data.table.s_table_h_1}</p></th>
                <th><p className={s.p}>{data.table.s_table_h_2}</p></th>
              </tr>
              <tr className={`${s.animatedText} ${s.row2}`}>
                <td className={s.p}>{data.table.s_table_t_1}</td>
                <td className={s.p}>{data.table.s_table_t_4}</td>
              </tr>
              <tr className={`${s.animatedText} ${s.row2}`}>
                <td className={s.p}>{data.table.s_table_t_2}</td>
                <td className={s.p}>{data.table.s_table_t_5}</td>
              </tr>
              <tr className={`${s.animatedText} ${s.row2}`}>
                <td className={s.p}>{data.table.s_table_t_3}</td>
                <td className={s.p}>{data.table.s_table_t_6}</td>
              </tr>
            </table>
          </div>
          <div className={s.animatedText}>
            <div className={s.pageDescription}>{data.description_2}</div>
          </div>
          <div style={{ display: 'none' }} className={s.animatedText}>
            <LinkBlock
              stys={{ marginTop: '55px' }}
              path="/"
              name={data.link}
              withArrow
            />
          </div>
        </div>
      </Container>
    </section>
  );

  return (
    isMobile
      ? (
        <ScrollAnimation animateOnce initiallyVisible animateIn={s.animateIn}>
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};

Strategy.propTypes = {
  activeSection: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isMobile: PropTypes.bool.isRequired,
  sectionName: PropTypes.string.isRequired,
  Pagination: PropTypes.node.isRequired,
};

export default Strategy;
