import PropTypes from 'prop-types';
import React from 'react';

import ScrollAnimation from 'react-animate-on-scroll';
import s from './contact-corporate.module.scss';
import Container from '../../../layouts/container/container';
import LinkBlock from '../../../components/link/link';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';

const ContactCorporate = ({ activeSection, data }) => {
  const renderSections = () => (
    <ScrollAnimation initiallyVisible animateIn={s.animateIn}>
      <section data-active={`active_${activeSection}`} className={`${s.contactSection} ${s.sections}`}>
        <VerticalLines />
        <Container>
          <div className={s.animatedTextContainer}>
            <div className={s.animatedText}>
              <h3 className={`${s.pageTitle} ${s.h3}`}>{data.title}</h3>
            </div>
            <div className={s.animatedText}>
              <LinkBlock
                path="/contact"
                name={data.link}
                withArrow
                location={{ isOpen: true }}
              />
            </div>
          </div>
        </Container>
      </section>
    </ScrollAnimation>
  );
  return (
    <>
      {renderSections()}
    </>
  );
};

ContactCorporate.propTypes = {
  activeSection: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContactCorporate;
